import {
  FormEvent,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import clsx from "clsx";
import { useDebouncedCallback } from "use-debounce";

import {
  FaSearch,
  FaCircleNotch,
  FaRegTimesCircle,
  FaLocationArrow,
} from "react-icons/fa";

import Downshift, {
  Actions,
  DownshiftState,
  StateChangeOptions,
} from "downshift";

import {
  MappingSearchResult,
  MappingSearchResults,
  autocomplete,
  getCoordinatesFromFeature,
  reverseGeocode,
} from "~/lib/mapping";

import { useStore } from "~/store";
import { Coordinates } from "@edwt/cms/prisma/getAllLocations";

const addressCookieName = "userAddress";

const AddressSearch = ({
  onInput = () => false,
  autoFocus,
  large = false,
}: {
  onInput?: FormEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  large?: boolean;
}) => {
  const router = useRouter();

  const downshiftRef = useRef<Downshift>();

  const [userAddress, userCoordinates, setUserAddress, hasHydrated] = useStore(
    (state) => [
      state.userAddress,
      state.geocodeCache.userCoordinates,
      state.setUserAddress,
      state.hasHydrated,
    ],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isGeolocating, setIsGeolocating] = useState<boolean>(false);

  // Run once store has been hydrated from localstorage
  useEffect(() => {
    if (hasHydrated) {
      const cookieAddress = Cookies.get(addressCookieName) ?? "";

      // If cookie and localStorage are out of sync, clear everything
      if (cookieAddress !== userAddress) {
        console.log("cookie and localStorage are out of sync");

        setUserAddress("")
          .then(() => {
            Cookies.set(addressCookieName, "");
          })
          .catch((error) => {
            console.error(error);
            Cookies.set(addressCookieName, "");
            router.push("/");
          });
      } else {
        // Update search input to stored address
        downshiftRef.current?.setState({ inputValue: userAddress });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasHydrated]);

  const [searchResults, setSearchResults] = useState<MappingSearchResults>([]);

  const onInputChanged = (address: string) => {
    // If address input is less than 5 characters, don't call API and clear suggestions
    if (address.length < 5) {
      setSearchResults([]);
      return;
    }

    fetchSearchResultsDebounced(address);
  };

  // Debounced address search
  const fetchSearchResultsDebounced = useDebouncedCallback(
    (address: string) => {
      const fetchSearchResults = async () => {
        const searchResults = await autocomplete(address);
        setSearchResults(searchResults);
      };
      fetchSearchResults();
    },
    // delay in ms
    500,
    {
      leading: true,
      trailing: true,
      maxWait: 750,
    },
  );

  const onAutocompleteSelect = (address: MappingSearchResult) => {
    if (address !== null) {
      handleSubmit(null, address.text);
    }
  };

  const handleGeolocationClick = async (setState: Actions<any>["setState"]) => {
    // Get user location using Browser API
    if (navigator.geolocation) {
      setIsLoading(true);
      setIsGeolocating(true);

      try {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            // Only update user address if user's new coordinates are different than the saved coordinates
            if (
              userCoordinates?.long !== longitude &&
              userCoordinates?.lat !== latitude
            ) {
              // console.log("Latitude: " + latitude + ", Longitude: " + longitude);
              const feature = await reverseGeocode({
                lat: latitude,
                long: longitude,
              });

              const coordinates = getCoordinatesFromFeature(feature);
              const address = feature.place_name;

              // console.log(feature.place_name, feature.center);

              setState({ inputValue: address });
              await updateUserAddress(address, {
                long: longitude,
                lat: latitude,
              });
            }

            setIsLoading(false);
            setIsGeolocating(false);
          },
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                alert(
                  "This website or your browser does not have permission to access your location",
                );
                break;
              case error.POSITION_UNAVAILABLE:
                alert(
                  "The acquisition of the geolocation failed because at least one internal source of position returned an internal error.",
                );
                break;
              case error.TIMEOUT:
                alert("Unable to acquire your location in a reasonable time");
                break;

              default:
                alert(
                  "An unknown error occurred while attempting to acquire your location",
                );
                break;
            }

            setIsLoading(false);
            setIsGeolocating(false);
          },
          {
            enableHighAccuracy: true,
            timeout: 10 * 1000,
            maximumAge: 60 * 1000,
          },
        );
      } catch (err) {
        console.error(err);
        setIsLoading(false);
        setIsGeolocating(false);
      }
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement> | null,
    input: string,
  ) => {
    event?.preventDefault();

    // Only update user address if it's different than the current address
    if (input !== userAddress) updateUserAddress(input);
  };

  async function updateUserAddress(address: string, coordinates?: Coordinates) {
    try {
      setIsLoading(true);
      await setUserAddress(address, coordinates);

      if (address) {
        Cookies.set(addressCookieName, address, { expires: 365 });

        router.push("/");
      } else {
        Cookies.remove(addressCookieName);

        router.push("/welcome");
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }

    setIsLoading(false);
  }

  function stateReducer(
    state: DownshiftState<MappingSearchResult>,
    changes: StateChangeOptions<MappingSearchResult>,
  ) {
    switch (changes.type) {
      // Auto-closes dropdown when there is no input, auto-highlight first option
      case Downshift.stateChangeTypes.changeInput:
        return {
          ...changes,
          isOpen: changes.inputValue === "" ? false : changes.isOpen,
          highlightedIndex:
            changes.highlightedIndex !== null && changes.inputValue !== ""
              ? changes.highlightedIndex
              : 0,
        };
      // Don't clear input value when input is blurred or user interacts with another element
      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.keyDownEscape:
      case Downshift.stateChangeTypes.blurInput:
      case Downshift.stateChangeTypes.touchEnd:
        return {
          ...changes,
          inputValue: state.inputValue,
        };
      default:
        return changes;
    }
  }

  const initialInputValue = userAddress;

  return (
    <Downshift
      id="edwt-address-search"
      onSelect={onAutocompleteSelect}
      onInputValueChange={onInputChanged}
      itemToString={(item: MappingSearchResult) => (item ? item.text : "")}
      initialInputValue={initialInputValue}
      stateReducer={stateReducer}
      ref={downshiftRef}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        clearSelection,
        selectedItem,
        getRootProps,
        setState,
      }) => (
        <div>
          <form
            className="relative flex w-full rounded-full bg-blue-lightest"
            onSubmit={(event) => handleSubmit(event, inputValue)}
          >
            <input
              {...getInputProps()}
              className={clsx(
                "relative w-full min-w-0 flex-grow bg-transparent py-1 pl-5 pr-1 placeholder-gray outline-none",
                {
                  "text-lg": large,
                },
              )}
              autoFocus={autoFocus}
              autoComplete="off"
              placeholder="Enter your address or click the arrow to fill automatically"
              aria-label="Address Entry"
            />
            {isOpen && searchResults.length > 0 && (
              <ul
                {...getMenuProps()}
                className="absolute top-full z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {searchResults.map((result, index) => (
                  <li
                    {...getItemProps({
                      key: index,
                      index,
                      item: result,
                    })}
                    key={index}
                    className={clsx(
                      "text-gray-900 relative cursor-default select-none px-3 py-3",
                      highlightedIndex === index
                        ? "bg-blue text-white"
                        : "bg-white text-black",
                    )}
                  >
                    {result.text}
                  </li>
                ))}
              </ul>
            )}
            <button
              type="button"
              aria-label="Clear Button"
              disabled={inputValue === ""}
              className={clsx(
                "transition-opacity disabled:w-0 disabled:px-0 xs:pl-1 xs:pr-1",
                {
                  "opacity-0": inputValue === "",
                },
              )}
              onClick={() => clearSelection()}
            >
              <FaRegTimesCircle className="text-blue-lighter hover:text-blue" />
            </button>
            <button
              className={clsx("pl-1 pr-1 text-blue", {
                "pl-2 pr-2 text-lg": large,
              })}
              type="button"
              aria-label="Use current location"
              onClick={() => handleGeolocationClick(setState)}
            >
              {isGeolocating ? (
                <FaLocationArrow className="max-w-full animate-pulse" />
              ) : (
                <FaLocationArrow className="max-w-full" />
              )}
            </button>
            <button
              type="submit"
              aria-label="Search Button"
              className={clsx(
                "m-1 flex h-[1.5rem] min-w-[1.5rem] items-center justify-center rounded-full bg-blue px-1 text-white",
                {
                  "h-[2rem] min-w-[2rem] text-lg": large,
                },
              )}
            >
              {isLoading ? (
                <FaCircleNotch className="max-w-full animate-spin p-0.5" />
              ) : (
                <FaSearch className="max-w-full p-0.5" />
              )}
            </button>
          </form>
        </div>
      )}
    </Downshift>
  );
};

export default AddressSearch;
